
import './App.css';
//import { useFetch } from './useFetch';
//import axios from 'axios'
import io from "socket.io-client";
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
const socket =io.connect("https://wpapi.loopciety.com",{}
);

function App() {
  
  //const {data}=useFetch(url);
  const[session, setSession]=  useState("");
  const[qrCode,setQrCode]= useState("");
  const[id, setId]=useState(''); 
  const createSesiionForWhatsapp=()=>{

    socket.emit("createSession",{
      id:session,
  
    });
  };
/*
  const Url='';
  const user={id:session}
  axios({
    method: 'post',
    url: Url,
    data: {user}
  })
  .then(data=>console.log(data))
  .catch(err=>console.log(err));

  */

  

  useEffect(() => {
    socket.emit("connected","hello from the client");
    socket.on("qr", (data)=>{
      const {qr} = data;
      console.log("QR recivido",qr);
      setQrCode(qr);
    });

    socket.on("ready",(data)=>{
      console.log(data);
      const{id}=data;
      setId(id);
    });

    socket.on("allChats",(data)=>{
      console.log("allChats",data);
      
    });
    socket.on("allCont",(data)=>{
      console.log("allCont",data);
      
    });
    
  },[]);

useEffect(() => {
    const handleSubmit = async (event) => {
      event.preventDefault();
      
      const phoneNumber = document.getElementById('phone-number').value;
      const imageUrl = document.getElementById('image-url').value;
     window.open(`whastapp.html?phone=${phoneNumber}&image=${encodeURIComponent(imageUrl)}`);
    
    };

    const form = document.getElementById('login-form');
    form.addEventListener('submit', handleSubmit);

    return () => {
      form.removeEventListener('submit', handleSubmit);
    };
  }, []);


  const getAllContacts=()=>{
    socket.emit('getAllContacts',{id});
  }

  const getAllChats=()=>{
    socket.emit('getAllChats',{id});
  }

  
  return (
    <div className="App">
      <h1>Iniciar whatsapp code </h1>
      <h1>QR</h1>
      <h2>Ingrese su numero</h2>
      <div style={{marginBottom:"40px"}}>
      
      <input type='text' id='newsesion'  value={session}  onChange={(e)=>{ setSession(e.target.value);}}></input>
      <button onClick={createSesiionForWhatsapp} >Crear session</button>
      
     
      </div>

	<div>
      <h1>Vizualizar whatsapp</h1>
        <form id="login-form">
          <div>
            <label htmlFor="phone-number">Numero de Telefono:</label>
            <input type="text" id="phone-number" name="phone-number" required /> 
          </div>
          <div>
            <label htmlFor="image-url">URL de la Imagen:</label>
            <input type="url" id="image-url" name="image-url" required /> 
          </div>
          <button type="submit">Iniciar Sesion</button>
        </form>

      </div>



     {qrCode && <QRCode value={qrCode}></QRCode>}
      <div >
        <table border="">
          <tr>
            <td colSpan={"4"}>Numero/id</td>
          </tr>
          <tr>
          <td>{id}</td>
          <td>{ id!== "" && <button onClick={getAllChats}>Obtener Chats</button> }</td>
          <td>{ id!== "" && <button onClick={getAllContacts}>Obtener Contactos</button>}</td>
	           
          </tr>
        </table>
      </div>
     
        
      
      
      
    </div>


  );
}

export default App;
